<template>
  <div>
    <div class="search-bar">
      <a-form-model ref="searchRef"
                    :model="searchForm"
                    :labelCol="{ span: 6 }"
                    :wrapperCol="{ span: 18 }">
        <a-row>
          <a-col :span="6">
            <a-form-model-item prop="name"
                               label="姓名">
              <a-input allowClear
                       v-model="searchForm.name"
                       placeholder="请输入员工姓名"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item prop="phone"
                               label="账号">
              <a-input allowClear
                       v-model="searchForm.phone"
                       placeholder="请输入登录手机号"></a-input>
            </a-form-model-item>
          </a-col>

          <a-col :span="6">
            <a-form-model-item prop="staff_role_id"
                               label="角色">
              <a-select placeholder="选择角色权限" style="width: 100%"
                        v-model="searchForm.staff_role_id"
                        show-search
                        allowClear
                        :default-active-first-option="false"
                        :show-arrow="false"
                        :filter-option="false"
                        @search="handleSuggestRoles">
                <a-select-option v-for="item in suggestRoleList"
                                 :key="item.id"
                                 :value="item.id.toString()">{{ item.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          
          <a-col :span="6">
            <a-form-model-item label="状态"
                               prop="status">
              <a-select v-model="searchForm.status">
                <a-select-option value="0">全部</a-select-option>
                <a-select-option value="-1">禁用</a-select-option>
                <a-select-option value="1">正常</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          
        </a-row>
        <a-row>
          <a-col :span="6">
            <a-form-model-item prop="outlets_city_adcode"
                               label="管理城市">
              <a-select v-model="searchForm.outlets_city_adcode"
                        style="width: 100%">
                <a-select-option key="" value="">不限</a-select-option>
                <a-select-option v-for="(item, index) in cityList"
                                 :key="index"
                                 :value="item.adcode">{{ item.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item prop="outlets_id"
                               label="管理网点">
              <a-select show-search allowClear
                        placeholder="请输入负责网点"
                        :default-active-first-option="false"
                        :show-arrow="false"
                        :filter-option="false"
                        :not-found-content="null"
                        @search="initOutletsList"
                        v-model="searchForm.outlets_id">
                <a-select-option v-for="(item, index) in outletsList"
                                 :key="index"
                                 :value="item.outlets_id">{{item.outlets_name}}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item prop="financial_account_id"
                               label="管理财务">
              <a-select v-model="searchForm.financial_account_id"
                        style="width: 100%">
                <a-select-option key="" value="">不限</a-select-option>
                <a-select-option v-for="(item, index) in accountList"
                                 :key="index"
                                 :value="item.id">{{ item.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <div class="flex justify-end" style="position: relative;top: 4px;">
              <a-button @click="handlerSearch"
                        type="primary"
                        html-type="submit">搜索</a-button>
              <a-button style="margin-left: 10px"
                        @click="resetForm">重置</a-button>
            </div>
          </a-col>
        </a-row>
      </a-form-model>
    </div>
    <base-table ref="tableRef"
                :columnsData="columns"
                rowKey="id"
                :tableData="tableData"
                :total="searchForm.total"
                :getListFunc="initData"
                :page.sync="searchForm.page"
                :pageSize.sync="searchForm.page_count">
      <template #toolbar>
        <div class="flex justify-between">
          <h4 class="text-lg font-bold">员工账号</h4>
          <a-button class="ml-3"
                    type="primary"
                    @click="handlerEdit('add')"
                    html-type="submit">新增</a-button>
        </div>
      </template>

      <template #status="{text}">
        <a-tag :color="text===1? 'green' : 'red' " class="m-0">{{text==1? '正常' : '停用'}}</a-tag>
      </template>
      <template #is_shop_client="{text}">
        <template>{{text == 2 ? "是" : "" }}</template>
      </template>
      <template #operation="{record}">
        <a-button type="link" class="pl-0 pr-0 ml-2 mr-2"
                  @click="handlerEdit('edit',record)">编辑</a-button>
        <a-button type="link" class="pl-0 pr-0 ml-2 mr-2"
                  @click="handlerUpdateStatus(record)">{{record.status === 1 ? '停用' : '启用'}}</a-button>
      </template>
    </base-table>

    <add-modal v-if="isEidtShow"
               :show.sync="isEidtShow"
               :id="activeId"
               :type="type" />
  </div>
</template>

<script>
import {
  getList,updateStatus
} from "@/api/system/staff.js"
import { getOutletsList } from '@/api/customer'
import {
  getList as getAccountList
} from "@/api/system/account.js"
import {
  getList as getRoleList
} from "@/api/system/role.js"
import { 
  // getCityList,
  getFinanceCityList
} from '@/api/outlets.js'
import addModal from './components/add-modal.vue'

export default {
  components: { addModal },
  data () {
    return {
      cityList: [],
      outletsList: [],
      searchForm: {
        name: "",
        phone: "",
        outlets_id: undefined,
        financial_account_id: "",
        outlets_city_adcode: "",
        staff_role_id: undefined,
        status: '1',
        total: 0,
        page: 1,
        page_count: 20,
      },
      columns: [
        {
          title: "账号ID",
          dataIndex: "id",
          align: "center",
          width: "70px",
        },
        {
          title: "姓名",
          dataIndex: "name",
          align: "center",
          width: 90,
        },
        {
          title: "登录手机号",
          dataIndex: "phone",
          align: "center",
          width: 120,
        },
        {
          title: "负责的网点",
          dataIndex: "outlets_name",
          align: "center",
        },
        {
          title: "管理的财务账户",
          dataIndex: "financial_accounts",
          align: "center",
        },
        {
          title: "管理的城市",
          dataIndex: "outlets_citys",
          align: "center",
        },
        {
          title: "角色",
          dataIndex: "staff_role_names",
          align: "center",
        },
        {
          title: "门店端使用",
          dataIndex: "is_shop_client",
          align: "center",
          slots: { customRender: "is_shop_client" },
          width: 110,
        },
        {
          title: "状态",
          dataIndex: "status",
          align: "center",
          width: "70px",
          slots: { customRender: 'status' }
        },
        {
          title: "操作",
          dataIndex: "operation",
          slots: { customRender: "operation" },
          align: "center",
          width: "140px",
        },
      ],

      type: "add",
      activeId: '',
      tableData: [],
      accountList: [],
      roleList: [],
      suggestRoleList: [],
      isEidtShow: false,
    }
  },
  mounted () {
    this.initAccountList()
    this.initRoleList()
    this.initCityList()
    this.initData()
  },
  methods: {
    async initData () {
      const { data, code } = await getList(this.searchForm)
      if (code === 0) {
        this.tableData = data.list
        this.searchForm.total = data.total_count
      }
    },

    async initAccountList () {
      const { data } = await getAccountList()
      this.accountList = data.list
    },
    async initRoleList () {
      const { code, data } = await getRoleList()
      if(code == 0){
        this.roleList = data.list
      }
      this.suggestRoleList = this.roleList || []
    },
    async initCityList () {
      const { data } = await getFinanceCityList()
      this.cityList = data.list
    },
    handleSuggestRoles(keyword){
      keyword = keyword || ""
      if(keyword){
        this.suggestRoleList = this.roleList.filter(el=>{
          return el.name.indexOf(keyword) > -1
        })
      }else{
        this.suggestRoleList = this.roleList
      }
    },
    async initOutletsList (value) {
      const params = {
        outlets_name: value,
      }
      if (value) {
        const { data, code } = await getOutletsList(params)
        if (code === 0) this.outletsList = data.list
      }
    },

    handlerSearch(){
      this.searchForm.page = 1
      this.initData()
    },

    handlerEdit (type, row) {
      this.type = type
      this.activeId = row ? row.id : ''
      this.isEidtShow = true
    },

    handlerUpdateStatus (row) {
      const _this = this
       this.$confirm({
        title: "温馨提示",
        content: `是否确认${row.status === 1 ? '停用': '恢复'}该账号？`,
        okText: "确认",
        cancelText: "取消",
        onOk() {
          updateStatus({ id: row.id, status: row.status === 1? -1: 1 }).then(
            (res) => {
              if (res.code === 0) {
                _this.$message.success("操作成功！")
                _this.initData()
              }
            }
          )
        },
      })
    },

    resetForm () {
      this.$refs.searchRef.resetFields()
      this.initData()
    },
  },
}
</script>

<style>
</style>